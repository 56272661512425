
import React from "react"
import { Global } from "@emotion/core"
import { ThemeProvider, CSSReset } from "@chakra-ui/core"

import theme from "../utils/theme"
import globalStyles from "../utils/globalStyles"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Global styles={globalStyles} />
      {children}
      <script async defer src="https://api.blobmaker.app/app.js"></script> 
      <noscript><img src="https://api.blobmaker.app/image.gif" alt="" /></noscript>
    </ThemeProvider>
  )
}

export default Layout
