
import { theme } from "@chakra-ui/core"
import { blue, pink, grey } from "material-ui-colors"

export default {
  ...theme.colors,
  blue,
  pink,
  gray: grey,
}
