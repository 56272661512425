
import React from "react"

export default {
  heart: {
    viewBox: "0 0 24 24",
    path: (
      <path
        stroke="currentcolor"
        fill="none"
        strokeWidth={2}
        d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
      />
    )
  },
  share: {
    viewBox: "0 0 24 24",
    path: (
      <g stroke="currentcolor" fill="none" strokeWidth={2}>
        <circle cx="18" cy="5" r="3" />
        <circle cx="6" cy="12" r="3" />
        <circle cx="18" cy="19" r="3" />
        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
      </g>
    ),
  },
  menu: {
    viewBox: "0 0 24 24",
    path: (
      <path
        stroke="currentcolor"
        strokeWidth={2}
        fill="none"
        d="M3,12L21,12M3,6L21,6M3,18,21,18"
      />
    ),
  },
  reload: {
    viewBox: "0 0 24 24",
    path: (
      <path
        stroke="currentcolor"
        strokeWidth={2}
        fill="none"
        d="M21.5 2v6h-6m-13 14v-6h6M2 11.5a10 10 0 0118.8-4.3m1.2 5.3a10 10 0 01-18.8 4.2"
      />
    ),
  },
  download: {
    viewBox: "0 0 24 24",
    path: (
      <path
        stroke="currentcolor"
        strokeWidth={2}
        fill="none"
        d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7 5V12m4 5l-4 4-4-4"
      />
    ),
  },
  code: {
    viewBox: "0 0 24 24",
    path: (
      <g stroke="currentcolor" strokeWidth={2} fill="none">
        <polyline points="16 18 22 12 16 6" />
        <polyline points="8 6 2 12 8 18" />
      </g>
    ),
  },
  twitter: {
    viewBox: "0 0 24 24",
    path: (
      <path
        fill="currentcolor"
        d="M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z"
      />
    ),
  },
  complexityStart: {
    viewBox: "0 0 40 40",
    path: (
      <g stroke="currentcolor" strokeWidth={2} fill="none">
        <path d="M20 7L36 30L4 35L20 7Z" stroke="currentcolor"/>
        <circle cx="20" cy="5" r="5" fill="currentcolor" stroke="#FFF"/>
        <circle cx="4" cy="35" r="5" fill="currentcolor" stroke="#FFF"/>
        <circle cx="36" cy="30" r="5" fill="currentcolor" stroke="#FFF"/>
        </g>
    ),
  },
  complexityEnd: {
    viewBox: "0 0 40 40",
    path: (
      <g stroke="currentcolor" strokeWidth={2} fill="none">
        <path d="M20 4L36 16.2229L29.8885 36H10.1115L4 16.2229L20 4Z" stroke="currentcolor"/>
        <circle cx="20" cy="4" r="5" fill="currentcolor" stroke="#FFF"/>
        <circle cx="10" cy="36" r="5" fill="currentcolor" stroke="#FFF"/>
        <circle cx="4" cy="17" r="5" fill="currentcolor" stroke="#FFF"/>
        <circle cx="36" cy="17" r="5" fill="currentcolor" stroke="#FFF"/>
        <circle cx="30" cy="36" r="5" fill="currentcolor" stroke="#FFF"/>
      </g>
    ),
  },
  contrastStart: {
    viewBox: "0 0 40 40",
    path: (
      <circle cx="20" cy="20" r="18" stroke="currentcolor" fill="#FFF" strokeWidth={2} />
    ),
  },
  contrastEnd: {
    viewBox: "0 0 40 40",
    path: (
      <path 
        stroke="currentcolor"
        fill="none"
        strokeWidth={2}
        d="M21.5856 8.93747C24.1222 10.1824 28.7589 8.54461 32.6083 9.46898C36.4577 10.3934 39.5199 13.8799 37.1913 15.2185C34.8627 16.5571 27.1434 15.7478 23.9803 18.2435C20.8171 20.7391 22.2102 26.5396 20.617 31.0769C19.0239 35.6142 14.4445 38.8883 12.514 36.6969C10.5835 34.5054 11.3019 26.8485 9.45474 22.5957C7.60761 18.3429 3.19498 17.4943 2.21843 15.6908C1.24187 13.8872 3.7014 11.1287 6.38819 9.81508C9.07499 8.50151 11.9891 8.63289 13.9916 7.14413C15.9942 5.65536 17.0852 2.54645 17.8489 3.05575C18.6126 3.56505 19.0491 7.69258 21.5856 8.93747Z"
      />
    ),
  },
  randomIcon: {
    viewBox: "0 0 32 32",
    path: (
      <path 
        stroke="none"
        fill="currentcolor"
        d="M7.38 5.555l15.592-1.367A3.419 3.419 0 0126.673 7.3L28.05 23.06a3.422 3.422 0 01-3.106 3.71L9.352 28.137a3.419 3.419 0 01-3.702-3.113L4.275 9.265a3.422 3.422 0 013.106-3.71zm.2 2.274a1.14 1.14 0 00-1.036 1.237l1.375 15.759a1.14 1.14 0 001.234 1.038l15.591-1.368a1.14 1.14 0 001.036-1.236l-1.376-15.76a1.14 1.14 0 00-1.234-1.037L7.58 7.829zm3.254 5.39a1.69 1.69 0 01-1.825-1.545 1.692 1.692 0 011.53-1.84 1.69 1.69 0 011.825 1.546 1.692 1.692 0 01-1.53 1.839zm10.065-.883a1.69 1.69 0 01-1.826-1.545 1.692 1.692 0 011.53-1.84 1.69 1.69 0 011.825 1.546 1.692 1.692 0 01-1.53 1.84zM11.72 23.373a1.69 1.69 0 01-1.825-1.545 1.692 1.692 0 011.53-1.84 1.69 1.69 0 011.825 1.545 1.692 1.692 0 01-1.53 1.84zm10.065-.883a1.69 1.69 0 01-1.825-1.545 1.692 1.692 0 011.53-1.84 1.69 1.69 0 011.825 1.546 1.692 1.692 0 01-1.53 1.84zm-5.476-4.635a1.69 1.69 0 01-1.825-1.546 1.692 1.692 0 011.53-1.839 1.69 1.69 0 011.825 1.545 1.692 1.692 0 01-1.53 1.84zM29.183 6.823l-.015.002A.915.915 0 0128.167 6c-.265-2.544-2.523-4.39-5.045-4.121h-.007a.916.916 0 01-1.002-.824.922.922 0 01.808-1.018h.002l.007-.001a6.387 6.387 0 014.718 1.408 6.498 6.498 0 012.347 4.363.922.922 0 01-.812 1.016zM8.547 32h-.008a6.395 6.395 0 01-4.578-1.818 6.51 6.51 0 01-1.96-4.553.92.92 0 01.895-.942h.016c.503-.008.917.4.926.91.044 2.559 2.134 4.595 4.67 4.55h.006a.918.918 0 01.927.91.92.92 0 01-.894.943z"
      />
    ),
  },
}
