
import { theme } from "@chakra-ui/core"

import customIcons from "./icons"
import colors from "./colors"

export default {
  ...theme,
  colors,
  icons: {
    ...theme.icons,
    ...customIcons,
  },
  fonts: {
    body: "'Inter', system-ui, sans-serif",
    heading: "'Inter', system-ui, sans-serif",
    mono: "IBM Plex Mono, Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    "2xl": "2rem",
    "3xl": "2.75rem",
    "4xl": "3.5rem",
    "5xl": "4.25rem",
    "6xl": "5rem",
  },
  lineHeights: {
    normal: "calc(1em + 0.5rem)",
    none: "1",
    shorter: "calc(1em + 0.125rem)",
    short: "calc(1em + 0.25rem)",
    base: "calc(1em + 0.5rem)",
    tall: "calc(1em + 0.75rem)",
    taller: "calc(1em + 1rem)",
    dense: "calc(1em + 0.25rem)",
    loose: "calc(1em + 0.75rem)",
  },
  shadows: {
    ...theme.shadows,
    outline: "0 0 0 0.25rem rgba(255,0,85,0.75)",
    custom: "0 0 3rem 0.25rem rgba(39, 48, 54, 0.07), 0 0.5rem 0.75rem -0.25rem rgba(39, 48, 54, 0.07)",
    large: "rgba(0, 0, 0, 0.01) 0px 1px 0px, rgba(0, 0, 0, 0.025) 0px 0px 8px, rgba(0, 0, 0, 0.075) 0px 20px 30px",
  }
}
