
import { css } from "@emotion/core"

const globalStyles = css`
  html {
    font-family: 'Inter', system-ui, sans-serif;
    line-height: calc(1em + 0.5rem);
  }
  .gatsby-highlight-code-line {
    display: block;
  }

  @keyframes haikeiBannerAnimation {
    from { transform: translateY(-100%); }
    to   { transform: translateY(0%);    }
  }
`

export default globalStyles
